<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="ylLists" :loading="loading" :scroll="{ x: 1600 }" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="ylLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/YLBXDetail', query: { id: record.SHEBAODATA_YANGLAO_ID, num: record.xuhao, type: 'edit' } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="add-item">
                <div @click="$router.push({ path: '/ReportForms/YLBXDetail', query: { type: 'add', id: '', num: '' } })">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;">增加一行
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
  { title: '序号', width: 100, dataIndex: 'xuhao', key: 'xuhao' },
  { title: '费款属期', width: 100, dataIndex: 'feikuanshuqi', key: 'feikuanshuqi' },
  { title: '缴费标志', width: 100, dataIndex: 'jiaofeibiaozhi', key: 'jiaofeibiaozhi' },
  { title: '本期参保人数', width: 150, dataIndex: 'benqicanbaorenshu', key: 'benqicanbaorenshu' },
  { title: '本期应缴合计金额', width: 150, dataIndex: 'benqiyingjiaohejijine', key: 'benqiyingjiaohejijine' },
  { title: '本期实缴合计金额', width: 150, dataIndex: 'benqishijiaohejijine', key: 'benqishijiaohejijine' },
  { title: '本期欠缴合计金额', width: 150, dataIndex: 'benqiqianjiaohejijine', key: 'benqiqianjiaohejijine' },
  { title: '本期应退合计金额', width: 150, dataIndex: 'benqiyingtuihejijine', key: 'benqiyingtuihejijine' },
  { title: '本期实退合计金额', width: 150, dataIndex: 'benqishituihejijine', key: 'benqishituihejijine' },
  { title: '本期在职缴费基数', width: 150, dataIndex: 'benqizaizhijiaofeijishu', key: 'benqizaizhijiaofeijishu' },
  { title: '本期单位缴费基数', width: 150, dataIndex: 'benqidanweijiaofeijishu', key: 'benqidanweijiaofeijishu' },
  { title: '本期个人应缴', width: 150, dataIndex: 'benqigerenyingjiao', key: 'benqigerenyingjiao' },
  { title: '本期单位应缴', width: 150, dataIndex: 'benqidanweiyingjiao', key: 'benqidanweiyingjiao' },
  { title: '补收个人应缴', width: 150, dataIndex: 'bushougerenyingjiao', key: 'bushougerenyingjiao' },
  { title: '补收单位应缴', width: 150, dataIndex: 'bushoudanweiyingjiao', key: 'bushoudanweiyingjiao' },
  { title: '本期个人实缴', width: 150, dataIndex: 'benqigerenshijiao', key: 'benqigerenshijiao' },
  { title: '本期单位实缴', width: 150, dataIndex: 'benqidanweishijiao', key: 'benqidanweishijiao' },
  { title: '补收个人实缴', width: 150, dataIndex: 'bushougerenshijiao', key: 'bushougerenshijiao' },
  { title: '补收单位实缴', width: 150, dataIndex: 'bushoudanweishijiao', key: 'bushoudanweishijiao' },
  { title: '本期退收合计金额', width: 150, dataIndex: 'benqituishouhejijine', key: 'benqituishouhejijine' },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 100,
    slots: { customRender: 'action' }
  }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getYLBXList(this.y)
            }
        },
        y: function (newVal) {
          if (newVal) {
            this.getYLBXList(this.y)
          }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            ylLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取养老保险列表
        getYLBXList (y) {
            this.loading = true
            this.ylLists = []
            this.$store.dispatch('getYLBXList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.ylLists.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'SHEBAODATA_YANGLAO_ID': datas[i].SHEBAODATA_YANGLAO_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'feikuanshuqi': datas[i].FEIYONGSHUQI,
                                'jiaofeibiaozhi': datas[i].JIAOFEIBIAOZHI,
                                'benqicanbaorenshu': datas[i].BENQICBRS,
                                'benqiyingjiaohejijine': datas[i].BQYJHEJE,
                                'benqishijiaohejijine': datas[i].BQSJHEJE,
                                'benqiqianjiaohejijine': datas[i].BQQJHJJE,
                                'benqiyingtuihejijine': datas[i].BQYTHJJE,
                                'benqishituihejijine': datas[i].BQSTHJJE,
                                'benqizaizhijiaofeijishu': datas[i].BQZZJFJS,
                                'benqidanweijiaofeijishu': datas[i].BQDWJFJS,
                                'benqigerenyingjiao': datas[i].BQGRYJ,
                                'benqidanweiyingjiao': datas[i].BQDWJY,
                                'bushougerenyingjiao': datas[i].BSGRYJ,
                                'bushoudanweiyingjiao': datas[i].BSDWYJ,
                                'benqigerenshijiao': datas[i].BQGRSJ,
                                'benqidanweishijiao': datas[i].BQDWSJ,
                                'bushougerenshijiao': datas[i].BSGRSJ,
                                'bushoudanweishijiao': datas[i].BSDWSJ,
                                'benqituishouhejijine': datas[i].BQTSHJJE
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.ylLists = []
                    }
                } else {
                    this.pagination.total = 0
                    this.ylLists = []
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            }).catch(e => {
                this.loading = false
                this.$message.error('系统异常，获取列表失败，请稍后重试！')
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getYLBXList(this.y)
        },
        // 删除单条养老保险
        onDel (item) {
            this.$store.dispatch('deleteYLBXItem', { SHEBAODATA_YANGLAO_ID: item.SHEBAODATA_YANGLAO_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getYLBXList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getYLBXList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .add-item{
            margin-top: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>
